/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { Menu, Dropdown, Modal, Spin } from "antd";
import { Alert } from "antd";
import { SaveOutlined, CheckCircleOutlined,CopyOutlined , ExportOutlined,MailOutlined, RedoOutlined, ExclamationCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import { HiArrowLeft } from "react-icons/hi2";
import "../styles/ContractNav.css";
import FormValidationModal from "./FormValidationModal";
import { message } from "antd";
import dotEnv from "dotenv";
import Logo from "../../Assets/Icons/newLogo.png";
import { useMediaQuery } from "react-responsive";
import { createOfferContractTypes, listingOrClientContractTypes } from "../../Common/commondata/commonData";
import CreateTemplateModal from "./CreateTemplateModal";
import { handleClose } from "../../Common/commonFunctions/handleClose";
import { SignableAndReadOnly } from "../../Common/components/SignableAndReadOnly";


dotEnv.config();
const { confirm } = Modal;

function ContractNav() {
 
  const dispatch = useDispatch();
  const history = useHistory();
  
  const query = new URLSearchParams(window.location?.search);
  const queryOrigin = query?.get("sameOrigin");
  const tabId = query?.get("tabId");
  const clientId = query?.get("reqId");
  let urlId = useParams()?.id;
  const [modalState, setModalState] = useState({
    createTemplateModal: false,
    validateModal: false,
    isMenuVisible: false,
    loadTemplates:false,
  });
  const [isEmailModalVisible, setIsEmailModalVisible] = useState({
    isEmailModalOpen: false,
    sendMultipleDoc: true,
  });
  const isMob = useMediaQuery({ maxWidth: 500 });

  const { contractAuthKey, rootDocData, contractData,documentBuyerIds,documentSellerIds } = useSelector((state) => state.createOffer);
  const { addClausesData, addDocumentsData, autoSaveContractStaus, transparentDivData } = useSelector((state) => state.contractStore);

  let openFor, contractType, invalidated, RTDInitiator, showSeller, showBuyer, offerId, documentId, ertsId, propertyId, signatureInfo, signable, builtForSection, createOrEditTemplate, sellerDocumentStatus, checkForDisable;
 
  if (rootDocData) {
    ({ openFor, contractType, invalidated, RTDInitiator, showSeller, showBuyer, offerId, documentId, ertsId, propertyId, signatureInfo, signable, builtForSection, createOrEditTemplate, sellerDocumentStatus, checkForDisable } = rootDocData);
  }



  const handlePrint = async () => {
    setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
    if (rootDocData) {
      dispatch(contractStoreHandle.downloadPdfFun());
    }
  };

  let loclData = JSON.parse(localStorage.getItem(urlId) || "{}") || {};
  if (loclData) {
    if (contractType === "DWR" || contractType === "BDS" || contractType === "BDB") {
    } else {
      if (addClausesData) loclData["addClauseData"] = addClausesData;
      if (addDocumentsData) loclData["addDocumentData"] = addDocumentsData;
      localStorage.setItem(urlId, JSON.stringify(loclData));
    }
  }

  const handleSaveButtonClick = () => {
    let localData = JSON.parse(localStorage.getItem(urlId)) || {};
    if (contractType === "ERTS" && !localData["FullAddress"]) {
      return message.error("Please enter full address in order to save the document.");
    }
    if (["ERTB", "ERTS", "ERTL", "ETCT"].includes(contractType) && (!localData["ListingPeriodBeginDate"] || !localData["ListingPeriodEndByDate"])) {
      return message.error(`Please enter Listing Period in section ${contractType === "ERTS" ? "3.7" : "3.6"}`);
    } else {
      dispatch(contractStoreHandle.autoSaveContract({currentUrlId:urlId, contractAuthKey}));
   }
  };

  const handleSaveAsTemplate = () => {
    setModalState((prevState) => ({ ...prevState, createTemplateModal: true, isMenuVisible: false }));
  };

  const handleCopyToDraft = () => {
    setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
    confirm({
      title: `This will create a copy of the existing offer with all of the existing values except the signatures and it will be available as a draft offer.`,
      content: "Are you sure you want to proceed?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(contractStoreHandle.copyAsDraftOfferFun({offerId, documentId, openFor}));
      },
      onCancel() {},
    });
  };
  const handleLoadTemplate = () => {
    setModalState((prevState) => ({ ...prevState, createTemplateModal: true, isMenuVisible: false, loadTemplates:true }));
    let query={
      contractType:contractType,
    }
    dispatch(contractStoreHandle.getTemplatesData({query}));
  }
  const handleMenu = (event) => {
    switch (event.key) {
      case "expand":
        // handleExpand();
        break;
      case "export": {
        handlePrint();
        break;
      }
      case "saveAsTemplate": {
        handleSaveAsTemplate();
        break;
      }
      case "loadTemplates": {
        handleLoadTemplate();
        break;
      }
      case "copyToDraft": {
        handleCopyToDraft();
        break;
      }
      case "inputOnly":
        // handleExpand();
        break;
      default:
        break;
    }
  };
  // dropdown menu options
  const menu = () => {
    let atleastOneBuyerSigned = false,
      atleastOneSellerSigned = false;
    signatureInfo?.forEach((element) => {
      if (element.roleType === "BUYER" || element.roleType === "BUYER_AGENT") {
        atleastOneBuyerSigned = true;
      } else {
        atleastOneSellerSigned = true;
      }
    });

    const handleResetSignature = () => {
      setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
      confirm({
        title: `Are you sure you want to reset all the signatures?`,
        content: "Note: Changes made cannot be reverted back again",
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(contractStoreHandle.resetSignatures(documentId, openFor, contractAuthKey));
        },
        onCancel() {},
      });
    };

    return (
      <>
        {(modalState?.isMenuVisible)? (
          <Menu onClick={handleMenu} style={{ background: "#124e8f", zIndex: 12 }} className="no-print">
            {documentId && (
              <Menu.Item key="export" style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }} icon={<ExportOutlined style={{ color: "#fff" }} />}>
                Export PDF
              </Menu.Item>
            )}
          {builtForSection === "DOCUMENT" && documentId && ( ([...createOfferContractTypes, "ERTB"].includes(contractType) && openFor==="BUYER_AGENT") || (listingOrClientContractTypes.includes(contractType) && openFor==="SELLER_AGENT") ) ? (
              <Menu.Item
                key="saveAsTemplate"
                style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }}
                icon={
                  <SaveOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
                Save As Template
              </Menu.Item>
            ) : null}
            {builtForSection === "DOCUMENT" && !signatureInfo?.length && ( ([...createOfferContractTypes, "ERTB"].includes(contractType) && openFor==="BUYER_AGENT") || (["ERTS"].includes(contractType) && openFor==="SELLER_AGENT") ) ? (
              <Menu.Item
                key="loadTemplates"
                style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }}
                icon={
                  <FileTextOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
             Load Template
              </Menu.Item>
            ) : null}
            {createOfferContractTypes.includes(contractType) && openFor === "BUYER_AGENT" && builtForSection === "DOCUMENT" && offerId && documentId ? (
              <Menu.Item
                key="copyToDraft"
                style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }}
                icon={
                  <CopyOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
                Copy To Draft
              </Menu.Item>
            ) : null}
            {((openFor === "BUYER_AGENT" && RTDInitiator === "BUYER" && atleastOneBuyerSigned && !atleastOneSellerSigned && !showSeller) || (openFor === "SELLER_AGENT" && RTDInitiator === "SELLER" && !atleastOneBuyerSigned && atleastOneSellerSigned && !showBuyer && contractType !== "ERTS") || (openFor === "SELLER_AGENT" && RTDInitiator === "SELLER" && contractType === "ERTS" && atleastOneSellerSigned && sellerDocumentStatus !== "SUBMITTED")) && documentId && contractType !== "TR" && contractType !== "TO" && !checkForDisable?.disabled ? (
              <Menu.Item onClick={handleResetSignature} key="resetSignature" style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }} icon={<RedoOutlined style={{ color: "#fff" }} />}>
                Reset Signatures
              </Menu.Item>
            ) : null}
          </Menu>
        ) : null}
      </>
    );
  };

  // when user clicks on the validate
  const handleValidate = () => {
    setModalState((prevState) => ({ ...prevState, validateModal: true }));
    dispatch(contractStoreHandle.validateForm(urlId, contractAuthKey));
  };


  let localData = JSON.parse(localStorage.getItem(urlId)) || {};
  const handleEmailIconClick = (event) => {
    
     if (!isEmailModalVisible.isEmailModalOpen) {
      setIsEmailModalVisible({
         ...isEmailModalVisible,
         isEmailModalOpen: true,
         sendMultipleDoc: true,
       });
     }}
  
  return (
    <>
      {localData && invalidated ? (
        <div style={{ position: "sticky", top: isMob ? '55px' : "70px", display: 'flex', justifyContent: 'center' }}>
          <Alert
            style={{ fontSize: isMob ? 10 : 16 }}
            showIcon
            message={`A more recent version of this document exists. Please see the current version in the Transaction Dashboard.`}
            type="error"
          />

        </div>
      ) : null}
      <div
        className="no-print"
        theme={"light"}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: "18px",
          zIndex: 10,
          width: "100%",
          minWidth: "-webkit-fill-available",
          background: "#124e8f",
          color: "#fff",
          border: "none",
          overflowX: "auto",
        }}
      >
        {/* {exportPDFMarker && <ExportProgressModal exportPDFMarker={exportPDFMarker} />} */}

        {/* Create Template Modal */}
        {modalState?.createTemplateModal && <CreateTemplateModal modalState={modalState} setModalState={setModalState} urlId={urlId} contractAuthKey={contractAuthKey} documentId={documentId} /> }
       
        {/* ValidationModal */}
        {modalState?.validateModal && <FormValidationModal modalState={modalState} setModalState={setModalState}  />}
        
        {(!documentId && autoSaveContractStaus) || transparentDivData?.loadingStatus ? (
          <div style={{ position: "fixed", zIndex: "1000", width: "100%", height: "100vh", backgroundColor: "black", opacity: "0.7", display: "flex", gap: "20px", flexDirection:"column", justifyContent: "center", alignItems: "center" }}>
            <Spin size="large" />
            {transparentDivData?.loadingStatus ? transparentDivData.message || "" : "Saving Contract..."}
          </div>
        ) : null}
        <div className={"navOptions"} >
          {builtForSection === "READ_ONLY_DOCUMENT" ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px" }}>
              <img style={{ width: "100px", height: "auto" }} src={Logo} alt="Logo" />
            </div>
          ) : (
            ""
          )}
          {builtForSection !== "READ_ONLY_DOCUMENT" ? (
            <div
              onClick={()=>handleClose({source: "ContractNav", builtForSection, urlId, tabId, openFor, contractType, documentId, ertsId, offerId, queryOrigin, clientId, propertyId, rootDocData, signatureInfo, history})}
              style={{
                display: "grid",
                gridTemplateRows: "0.3fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <div>
                <HiArrowLeft
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </div>
              <div>Back</div>
            </div>
          ) : (
            <div></div>
          )}
          {contractType !== "TR" && builtForSection !== "READ_ONLY_DOCUMENT" && contractData?.backendValidationObject?.length ? (
            <div
              style={{
                display: "grid",
                gridTemplateRows: "0.3fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px 0 10px 0",
                cursor: "pointer",
                pointerEvents: invalidated ? "none" : "auto",
              }}
              onClick={handleValidate}
            >
              <div>
                <CheckCircleOutlined
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </div>
              <div>Validate</div>
            </div>
          ) : null}

          {((builtForSection !== "READ_ONLY_DOCUMENT") || (openFor === "TITLE_COMPANY")||(builtForSection === "READ_ONLY_DOCUMENT")) ? (
            <div
              style={{
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px 0 10px 0",
                color: "#fff",
                cursor: "pointer",
                pointerEvents: invalidated ? "none" : "auto",
              }}
            >
              <Dropdown trigger={["click"]} overlay={menu} onVisibleChange={(visible) => setModalState((prevState) => ({ ...prevState, isMenuVisible: visible }))}>
                <ExportOutlined
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </Dropdown>
              <Dropdown trigger={["click"]} overlay={menu} onVisibleChange={(visible) => setModalState((prevState) => ({ ...prevState, isMenuVisible: visible }))}>
                <div>Option</div>
              </Dropdown>
            </div>
          ) : (
            <div></div>
          )}

          {((builtForSection !== "READ_ONLY_DOCUMENT") || ((openFor === "TITLE_COMPANY") && (contractType === "CI"))) && signable === true ? (
            <div
              style={{
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px",
                cursor: autoSaveContractStaus ? "context-menu" : "pointer",
                pointerEvents: invalidated ? "none" : "auto",
              }}
            >
              {builtForSection === "TEMPLATE" && createOrEditTemplate === false ? (
                <></>
              ) : (
                <>
                  <div style={{position:"relative"}}>
                    {autoSaveContractStaus ? (
                      <Spin size={isMob ? "" : "large"} className="custom-spinner" style={{ position: "absolute", fontSize: "20px", top: isMob ? 0 : 5, right: isMob ? "-10px" : "-15px" }} />
                    ) : (
                      <SaveOutlined
                        style={{
                          color: "#fff",
                          fontSize: "larger",
                          fontWeight: "bolder",
                        }}
                        { ...((builtForSection !== "READ_ONLY_DOCUMENT" && !checkForDisable?.disabled) ? {onClick: () => handleSaveButtonClick()} : {})}
                      />
                    )}
                  </div>
                  {autoSaveContractStaus ? null : <div { ...(((builtForSection !== "READ_ONLY_DOCUMENT") && !checkForDisable?.disabled) ? {onClick: () => handleSaveButtonClick()} : {})}>Save</div>}
                </>
              )}
            </div>
          ) : (
            <div></div>
          )}
          {builtForSection !== "READ_ONLY_DOCUMENT"&& documentId && (openFor==="BUYER_AGENT" || openFor==="SELLER_AGENT" )? (
            <div
              onClick={handleEmailIconClick} 
              style={{
                display: "grid",
                gridTemplateRows: "0.3fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <div>
                <MailOutlined
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </div>
              <SignableAndReadOnly
                isEmailModalVisible={isEmailModalVisible}
                setIsEmailModalVisible={setIsEmailModalVisible}
                userAuthKey={contractAuthKey}
                documentBuyerIds={documentBuyerIds}
                documentSellerIds={documentSellerIds}
                source="CONTACT_NAV"
                rootDocData={rootDocData}
                role={rootDocData?.openFor === "BUYER_AGENT" ? "BUYER" : "SELLER"}
                offerId={rootDocData?.offerId}
              />
              <div>Send Email</div>
            </div>
          ) : (
            <div></div>
          )}
         
        </div>
      </div>
    </>
  );
}

export { ContractNav };
