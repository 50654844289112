/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox} from "antd";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import axios from "axios";
import { closingActions } from "../../TransactionReport/state/actions";
import { contractDataHandle } from "../../CreateOffer/state/actions";
import moment from "moment";

const DocumentList = ({ isSmallScreen,role, isMediumScreen, data, setData, screenSource, selectedData, section, documentId, selectedStatus, nestedModalOpen, RTDInitiator, offerId, contractAuthKey, rootDocData }) => {
  
  const { getTransactionLaneData } = useSelector((state) => state.rtdTransactionLane);
  const { sharedDocs, sharedDocsLoading, sharedDocsError } = useSelector((state) => state.transactionReport);
  let { propertyDocs, propertDocsLoading, propertDocsError, personDocs, personDocsLoading, personDocsError } = useSelector((state) => state.createOffer, shallowEqual);
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location?.search);
  const clientId = query?.get("reqId");
  let clientDocuments = ["DWR", "BDS", "BDB", "ERTB", "ERTL", "ETCT", "LCAE","LCAES_ETCT","LCAES_ERTL"];
  const crmSellerDocs = [
    'BDS',
    'DWR',
    'ERTL',
    'ETCT',
    'LCAES_ERTL',
    'LCAES_ETCT',
  ];
  let unmounted = {
    value: false,
  };
  let source = axios.CancelToken.source();

  useEffect(() => {
    
    if (offerId || screenSource === "SELLING_RTD") {
      dispatch(closingActions.getSharedDocs(offerId, contractAuthKey));
    }
    if (clientDocuments.includes(rootDocData?.contractType)) {
      dispatch(contractDataHandle.getPersonDocs({ id: clientId, unmounted, source, contractAuthKey }));
    }
    let params = {};
if(screenSource === "SELLING_RTD")
{
  params = {
 ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
   openFor: screenSource === "SELLING_RTD" ? "SELLER_AGENT": "BUYER_AGENT",
  }  
}else{
 params = {
    ...(rootDocData?.thirdpartyId ? { thirdpartyIdDoc: rootDocData?.thirdpartyId } : {}),
    ...(rootDocData?.offerId ? { offerId: rootDocData?.offerId } : {}),
    // ...(rootDocData?.propertyId ? { propertyId: rootDocData?.propertyId } : {}),
    ...(rootDocData?.contractType === "ERTS" || rootDocData?.ertsId 
      ? { ertsId: rootDocData?.contractType === "ERTS" 
          ? rootDocData?.documentId 
          :rootDocData?.ertsId } 
      : {}),
   
    openFor: screenSource === "CONTACT_SELLING_RTD" ? "SELLER_AGENT" : "BUYER_AGENT",
  };
}
    if (!clientDocuments.includes(rootDocData?.contractType)) {
      dispatch(contractDataHandle.getPropertyDocsFun({ params, unmounted, source, contractAuthKey }));
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, []);

  const getClientDocName = (data={}, name="") => {
    let { contractType, transactionData = {} } = data ;
    switch (contractType) {
      case "ERTL":
      case "ETCT":
      case "ERTB": {
        let startDate = transactionData?.ListingPeriodBeginDate ? moment(transactionData?.ListingPeriodBeginDate).format("MM/DD/YYYY, h:mm A") : "";
        let endDate = transactionData?.ListingPeriodEndByDate ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A") : "";
        return startDate && endDate ? `${name} ${startDate} - ${endDate} ` : startDate ? `${name} ${startDate}` : endDate ? `${name} ${endDate}` : `${name}`;
      }
      case "LCAE":
      case "LCAES_ETCT":
      case "LCAES_ERTL": {
        let endDate = transactionData?.ListingPeriodEndByDate ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A") : "";
        return endDate ? `Amend/Extend -  ${endDate}` : `Amend/Extend`;
      }

      default:
        let dateTime = data?.createdAt ? moment(data?.createdAt).format("MM/DD/YYYY, h:mm A") : "";
        return `${name} ${dateTime}`;
    }
  };
  const mapClientDocs = (docsTabDoraDocs, propertyDocsData) => {
    let clientDocuments = [];

    docsTabDoraDocs?.forEach((doc) => {
      if (doc.createdDocuments?.length > 0) {
        doc.createdDocuments.forEach((subDoc) => {
          subDoc.contractName = getClientDocName(subDoc, doc?.name);
          clientDocuments.push(subDoc);
          if (subDoc.matchedDocuments?.length > 0) {
            subDoc.matchedDocuments.forEach((matchDocument) => {
              matchDocument.contractName = getClientDocName(matchDocument);
              matchDocument.isSecondLevel = true;
              clientDocuments.push(matchDocument);
            });
          }
        });
      }
    });

    return clientDocuments;
  };
  const clientDocs = mapClientDocs(personDocs);

  useEffect(() => {
    let propertyDocuments;
    const filteredClientContracts = clientDocs?.filter((contract) => {
      return contract._id === rootDocData?.documentId;
    });
     if (screenSource === "SELLING_RTD") {
      propertyDocuments = propertyDocs?.filter((contract) => {
        return contract?.documentId === documentId;
      });
    }else{
     propertyDocuments = propertyDocs?.filter((contract) => {
      return contract.documentId === rootDocData?.documentId;
    });
  }
    if (filteredClientContracts && filteredClientContracts.length > 0) {
      // Extract both documentIds and sentBy from filtered contracts
      const documentDetails = filteredClientContracts
        .map((contract) => ({
          documentId: contract?._id,
          sentBy:crmSellerDocs.includes(contract?.contractType)?"SELLER_AGENT":"BUYER_AGENT"  // Ensure that the sentBy field exists in your contracts
        }))
        .filter(({ documentId, sentBy }) => documentId !== undefined && sentBy !== undefined); // Filter out any undefined values

      // Update state with the filtered documentDetails
      setData((prevState) => ({
        ...prevState,
        clientDocumentSelected: documentDetails,
      }));
    }

    if (propertyDocuments && propertyDocuments.length > 0) {
      // Extract documentIds from filtered contracts
      const documentIds = propertyDocuments.filter(
        ele =>
          (ele?.contractType === "ERTS" || ele?.contractType === "LCAES") ||
          ele?.hasOwnProperty('isShared') ||
          ele?.offerId
      ).map((contract) => contract.documentId).filter((documentId) => documentId !== undefined);
      // Update state with the filtered documentIds
      setData((prevState) => ({
        ...prevState,
        propertyDocsSelected: documentIds,
      }));
    }

    if (propertyDocuments && propertyDocuments.length > 0) {
      // Extract documentIds from filtered contracts
      const documentIds = propertyDocuments.filter(
        ele =>
          !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") &&
          !ele?.hasOwnProperty('isShared') &&
          !(ele?.offerId)
      ).map((contract) => contract.documentId).filter((documentId) => documentId !== undefined);
      // Update state with the filtered documentIds
      setData((prevState) => ({
        ...prevState,
        documentWithoutBuyerSelected: documentIds,
      }));
    }
  }, [getTransactionLaneData,personDocs,propertyDocs]);
  const handleCheckboxChange = (documentId) => {
    let flag = [...data?.documentSelected];
    const value = flag?.includes(documentId) ? { documentSelected: flag?.filter((id) => id !== documentId) } : { documentSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };
  const handleClientDocChange = (documentId, sentBy) => {
    // Create a new array of clientDocumentSelected
    let flag = [...data?.clientDocumentSelected];

    // Create an object with documentId
    const newDoc = { documentId, sentBy };

    // Check if the documentId already exists in the array
    const index = flag.findIndex((item) => item.documentId === documentId);

    let newSelectedDocs;
    if (index !== -1) {
      // Remove the existing document object from the array
      newSelectedDocs = flag.filter((_, i) => i !== index);
    } else {
      // Add the new document object to the array
      newSelectedDocs = [...flag, newDoc];
    }

    // Update the state with the new array
    setData({
      ...data,
      clientDocumentSelected: newSelectedDocs,
    });
  };

  const handleSharedDocs = (documentId) => {
    let flag = [...data?.sharedSelected];
    const value = flag?.includes(documentId) ? { sharedSelected: flag?.filter((id) => id !== documentId) } : { sharedSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };

  const handlePropertyDocs = (documentId, type) => {
    let flag = [];

    if (type === "PROPETY_DOC") {
      flag = [...data?.propertyDocsSelected];
    } else if (type === "DOCUMENT_WITHOUT_BUYERS") {
      flag = [...data?.documentWithoutBuyerSelected];
    }

    const value = flag.includes(documentId) ? flag.filter((id) => id !== documentId) : [...flag, documentId];

    setData({
      ...data,
      ...(type === "PROPETY_DOC" ? { propertyDocsSelected: value } : {}),
      ...(type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: value } : {})
    });
  };

  const handleSelectAll = ({ value, element, type }) => {
    if (value) {
      if (type === "CLIENT DOCUMENT") {
        // Map to include both documentId and sentBy for CLIENT DOCUMENT
        const clientDocumentObjects = element.map((document) => ({
          documentId: document._id, // Assuming _id is used as documentId
          sentBy: crmSellerDocs.includes(document?.contractType)?"SELLER_AGENT":"BUYER_AGENT"
        }));

        setData((prevData) => ({
          ...prevData,
          clientDocumentSelected: clientDocumentObjects,
        }));
      } else {
        // Handle other types
        const allDocumentIds = element.map((document) => (type === "DOCUMENT" ? document.documentId : document._id));

        setData((prevData) => ({
          ...prevData,
          ...(type === "DOCUMENT" ? { documentSelected: allDocumentIds } : type === "SHARED" ? { sharedSelected: allDocumentIds } : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: allDocumentIds } : { propertyDocsSelected: allDocumentIds }),
        }));
      }
    } else {
      // Handle the case where value is false
      setData((prevData) => ({
        ...prevData,
        ...(type === "DOCUMENT" ? { documentSelected: [] } : type === "SHARED" ? { sharedSelected: [] } : type === "CLIENT DOCUMENT" ? { clientDocumentSelected: [] } : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: [] } : { propertyDocsSelected: [] }),
      }));
    }
  };

  const transactionAndPropertyDocs = propertyDocs?.filter((ele) => ele?.contractType === "ERTS" || ele?.contractType === "LCAES" || ele?.hasOwnProperty("isShared") || ele?.offerId);

  return (
    <div style={{ width: "100%" }}>
      {personDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : personDocsError ? (
        ""
      ) : (
        clientDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Client Documents:</p>

                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: clientDocs, type: "CLIENT DOCUMENT" })} checked={data?.clientDocumentSelected?.length === clientDocs?.length}>
                    <text style={{ color: "grey" }}>Select All</text>
                  </Checkbox>
                </div>
                {clientDocs?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", flexDirection: "column" }}>
                    {document?.isSecondLevel && (
                      <>
                        <div style={{ marginLeft: "20px" }}>
                          <Checkbox style={{ marginRight: "10px" }} checked={data?.clientDocumentSelected?.some((item) => item.documentId === document._id)} onChange={() => handleClientDocChange(document._id, crmSellerDocs.includes(document?.contractType) ? "SELLER_AGENT" : "BUYER_AGENT")}>
                            {document.contractName}
                          </Checkbox>
                        </div>
                      </>
                    )}
                    {!document.isSecondLevel && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ marginRight: "10px" }} checked={data?.clientDocumentSelected?.some((item) => item.documentId === document._id)} onChange={() => handleClientDocChange(document._id, crmSellerDocs.includes(document?.contractType) ? "SELLER_AGENT" : "BUYER_AGENT")}>
                          {document?.contractName}
                        </Checkbox>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}

      {/* // Property documents list  */}
      {propertDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : propertDocsError ? (
        ""
      ) : (
        propertyDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              {transactionAndPropertyDocs?.length > 0 ? (
                <div style={{ padding: "5px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey", height: "40px" }}>
                    <p>Transaction & Property Documents:</p>
                    <Checkbox
                      onChange={(e) =>
                        handleSelectAll({
                          value: e.target.checked,
                          element: propertyDocs.filter((ele) => ele?.contractType === "ERTS" || ele?.contractType === "LCAES" || ele?.hasOwnProperty("isShared") || ele?.offerId),
                          type: "PROPETY_DOC",
                        })
                      }
                      checked={data?.propertyDocsSelected?.length === propertyDocs.filter((ele) => ele?.contractType === "ERTS" || ele?.contractType === "LCAES" || ele?.hasOwnProperty("isShared") || ele?.offerId).length}
                    >
                      <text style={{ color: "grey" }}>Select All</text>
                    </Checkbox>
                  </div>
                  {transactionAndPropertyDocs.map((document, index) => (
                    <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                      <Checkbox style={{ marginRight: "10px" }} checked={data?.propertyDocsSelected?.includes(document._id)} onChange={() => handlePropertyDocs(document._id, "PROPETY_DOC")}>
                        {document?.contractId ? document?.documentName : document?.fileName}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              ) : null}

              {/* Section for Documents without Buyers */}
              {propertyDocs.filter((ele) => !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") && !ele?.hasOwnProperty("isShared") && !ele?.offerId).length > 0 && (
                <div style={{ padding: "10px" }}>
                  <div style={{ display: "flex", height: "40px", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                    <p>Documents without Buyers:</p>
                    <Checkbox
                      onChange={(e) =>
                        handleSelectAll({
                          value: e.target.checked,
                          element: propertyDocs.filter((ele) => !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") && !ele?.hasOwnProperty("isShared") && !ele?.offerId),
                          type: "DOCUMENT_WITHOUT_BUYERS",
                        })
                      }
                      checked={data?.documentWithoutBuyerSelected?.length === propertyDocs.filter((ele) => !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") && !ele?.hasOwnProperty("isShared") && !ele?.offerId).length}
                    >
                      <text style={{ color: "grey" }}>Select All</text>
                    </Checkbox>
                  </div>
                  {propertyDocs
                    .filter((ele) => !(ele?.contractType === "ERTS" || ele?.contractType === "LCAES") && !ele?.hasOwnProperty("isShared") && !ele?.offerId)
                    .map((document, index) => (
                      <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ marginRight: "10px" }} checked={data?.documentWithoutBuyerSelected?.includes(document._id)} onChange={() => handlePropertyDocs(document._id, "DOCUMENT_WITHOUT_BUYERS")}>
                          {document?.contractId ? document?.documentName : document?.fileName}
                        </Checkbox>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        )
      )}
      {/* shared document list  */}
      {sharedDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : sharedDocsError ? (
        ""
      ) : (
        sharedDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Shared Documents:</p>
                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: sharedDocs, type: "SHARED" })} checked={data?.sharedSelected?.length === sharedDocs?.length}>
                    <text style={{ color: "grey" }}>Select All</text>
                  </Checkbox>
                </div>
                {sharedDocs?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                    <Checkbox style={{ marginRight: "10px" }} checked={data?.sharedSelected?.includes(document._id)} onChange={() => handleSharedDocs(document._id)}>
                      {document.fileName}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DocumentList;
