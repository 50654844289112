import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import styles from "../styles/ClientList.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const apiURL = process.env.REACT_APP_BASE_URL;

export default function AddClient({
  visible,
  setVisible,
  setClientData,
  accessTokan,
  clientType,
  source,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);

  const onReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    setVisible(false);
    onReset();
  };

  const onFinish = (values) => {
    onReset();
    setLoading(true);
    axios
      .post(
        `${apiURL}/customer/createCustomer`,
        {
          ...{ roleType: "CUSTOMER" },
          ...values,
          firstName:
            values.firstName.charAt(0).toUpperCase() +
            values.firstName.slice(1),
          middleName: values.middleName
            ? values.middleName.charAt(0).toUpperCase() +
              values.middleName.slice(1)
            : "",
          lastName:
          values.lastName ? (values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1)) : "",
          email: values.email.toLowerCase(),
        },
        {
          headers: {
            accept: "application/json",
            authorization: `bearer ${accessTokan}`,
          },
        }
      )
      .then((response) => {
        const element = document.getElementsByName("successMessage");
        setLoading(false);
        if (element) {
          element[0].style.display = "block";
          element[1].style.display = "none";
        }
        setTimeout(() => {
          const element = document.getElementsByName("successMessage");
          if (element[0]) {
            element[0].style.display = "none";
          }
        }, 2000);
        axios
          .get(`${apiURL}/realtorCustomerList`, {
            headers: {
              accept: "application/json",
              authorization: `bearer ${accessTokan}`,
            },
          })
          .then((res) => {
            setClientData(res.data.info);
          })
          .catch((error) => {
            // console.log(error);
          });

      })
      .catch((err) => {
        setLoading(false);
            message.error(err?.response?.data?.message || "We encountered an issue while creating custumer profile.Please try again later!");

        const element = document.getElementsByName("successMessage");

        if (element) {
          element[1].style.display = "block";
          element[0].style.display = "none";
        }
        setTimeout(() => {
          const element = document.getElementsByName("successMessage");
          if (element) {
            element[1].style.display = "none";
          }
        }, 2000);
        // console.log(err);
      });
  };

  return (
    visible && (
      <Drawer
        title = { <div className={styles.createtitle} style={{ color: "#8C8C8C" }}>Create a new {source === "ContactSheet" ? "Contact" : clientType==="Buyers"?"Buyer":"Seller"}</div>}
        placement="right"
        visible={visible}
        onClose={handleCancel}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "80px",
              height: "200px",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={styles.formcreate}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  className="extraDrawer"
                  rules={[
                    { required: true, message: "Please Enter First Name!" },
                  ]}
                >
                  <Input placeholder="Enter First Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={11} style={{ marginLeft: "15px" }}>
                <Form.Item
                  name="middleName"
                  label="Middle Name"
                  className="extraDrawer"
                >
                  <Input placeholder="Enter Middle Name" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  className="extraDrawer"
                  rules={[
                    { required: true, message: "Please Enter Last Name" },
                  ]}
                >
                  <Input placeholder="Enter Last Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={11} style={{ marginLeft: "15px" }}>
                <Form.Item
                  name="email"
                  label="Email"
                  className="extraDrawer"
                  hasFeedback
                  rules={[
                    {
                      type: "email",
                      message: "Not a valid Email!",
                    },
                    {
                      required: true,
                      message: "Please Enter Email!",
                    },
                    { whitespace: true },
                  ]}
                >
                  <Input placeholder="Enter Email"  allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  className="extraDrawer"
                  hasFeedback
                  rules={[
                    { required: true, message: "" },
                    { whitespace: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value?.length === 10) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter 10 digit Phone Number!")
                        );
                      },
                    }),
                  ]}
                >
                  <PhoneInput
                    inputClass="phoneInput"
                    inputStyle={{
                      width: "100%",
                    }}
                    country={"us"}
                    onlyCountries={["us"]}
                    maxLength="10"
                    international={false}
                    disableCountryCode={true}
                    placeholder="Enter Phone Number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="notes"
                  label="Notes"
                  className="extraDrawer"
                  // style={{ color: "red" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "please enter notes",
                  //   },
                  // ]}
                >
                  {/* <h3 className="extraDrawer">Notes</h3> */}
                 <Input.TextArea placeholder="Add Notes" />
                </Form.Item>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{ marginBottom: "20px", marginTop: "-10px" }}
            >
              <Typography.Text
                type="success"
                name="successMessage"
                style={{ display: "none" }}
              >
                Buyer Added Successfully!
              </Typography.Text>
              <Typography.Text
                type="danger"
                name="successMessage"
                style={{ display: "none" }}
              >
                Something went wrong!
              </Typography.Text>
            </Row>
            <Row justify="center">
              <Row span={9}>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ marginRight: "20px" }}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Row>
            </Row>
          </Form>
        )}
      </Drawer>
    )
  );
}
