import accounting from "accounting";
import calculatePriceTable from "../helpers/calculatePriceTable";
import removeSection from "../helpers/removeSection";
import dotEnv from "dotenv";
import { render } from "react-dom";
import HandleSignatureBox from "../../components/HandleSignatureBox";
import { checkListingContainer, checkSellingContainer, handleConfirmModal,  handleToggleAutoFillDates, handleToggleAutoPriceCalculation, pasteListingCoDataIntoSellingCo, pasteSellingCoDataIntoListingCo, showDeleteConfirmToBroker, updateIndividualChangedField } from "../helperFunctions";
import { contractDataHandle } from "../../state/actions";
import { formatPhoneNumber } from "../../../Common/utils/universalFuntions";
import {  noChangeCheckboxContractTypes } from "../../../Common/commondata/commonData";

dotEnv.config();

// const apiURL = process.env.REACT_APP_BASE_URL;
// const Buffer = require("buffer").Buffer;

let needsDbUpdate = false;
const timeouts = [];
let timeoutInput;
let globalSpecificData = {},
  priceInputFieldChange = false;
export const handleClearTimeouts = () => {
  if (timeouts.length > 0) {
    // console.log("canceling pending db updates");
    timeouts.forEach((to) => clearTimeout(to));
  }
};

export const handleCheckboxGroupUpdate = (selectedValue, cbGroup, specificData) => {
  // console.log("selectedValue", selectedValue, "cbGroup", cbGroup);
  document.querySelectorAll(`[data-cb-group="${cbGroup}"]`)?.forEach((element) => {
    if (element.value !== selectedValue && element.checked) {
      // console.log("setting checked on", element.name, element.value, " to false");
      element.checked = false;
      // if (element.value === "Deleted" && element.checked === false) {
      //   const currentRow = element.closest("tr");
      //   if (currentRow && currentRow.classList.contains("strikeThrough")) {
      //     currentRow.classList.remove("strikeThrough");
      //   }
      // }
      if (element.hasAttribute("data-enable-source")) {
        // console.log("clearing input of checkbox target");
        document.querySelectorAll(`[data-enable-target="${element.dataset?.enableSource}"]`).forEach((ele) => {
          if (ele.type !== "checkbox") {
            ele.value = "";
            specificData[ele.name] = "";
            if (ele.parentNode.classList.contains("grow-with-input") || ele.parentNode.classList.contains("grow-with-content")) {
              ele.parentNode.dataset.replicatedValue = "";
            }
          } else {
            // target is a checkbox
            // console.log("target is checkbox to turn off");
            ele.checked = false;
            specificData[ele.name] = "";
          }
        });
      }

      if (element.hasAttribute("data-linked-value")) {
        // console.log("clearing input of checkbox linked value");
        document.querySelectorAll(`[data-linked-value="${element.name}"][name="${element.dataset.linkedValue}"]`).forEach((ele) => {
          ele.value = "";
          specificData[ele.name] = "";
          if (ele.parentNode.classList.contains("grow-with-input") || ele.parentNode.classList.contains("grow-with-content")) {
            ele.parentNode.dataset.replicatedValue = "";
          }
        });
      }
    }
  });
};

export const handleClickEvent = ({event, currentUrlId, rootDocData, dispatch, handleInputSave}) => {
  let pan1 = document.getElementById("pan1");
  if (event.target.hasAttribute("data-scroll-to")) {
    if (window.innerWidth <= 500) {
      dispatch(contractDataHandle.setDrawerPosition(false));
      pan1.style.animation = "sidebar-unload 400ms ease-out";
      setTimeout(() => {
        pan1.style.visibility = "hidden";
      }, 400);
    }
    const scrollTo = document.getElementById(event.target.dataset.scrollTo);
    scrollTo.scrollIntoView({ block: "center", inline: "start" });
    scrollTo.style.backgroundColor = "rgb(64, 255, 53)";
    setTimeout(() => (scrollTo.style.backgroundColor = ""), 3000);
  } else if (rootDocData?.contractType === 'TR' && event.currentTarget.hasAttribute('data-TR-expand-table')) {
    // Transaction report > Expand table logic
    // let row = event.currentTarget;
    let row = event.target.closest('tr');
    var listContainer = row?.querySelector('.list-container');
    var dropdownIcon = row?.querySelector('.dropdown-icon i');
    if (listContainer && dropdownIcon) {
      var isVisible = listContainer.style.display === 'block';
      listContainer.style.display = isVisible ? 'none' : 'block';
      dropdownIcon.classList.toggle('bi-caret-right-fill', isVisible);
      dropdownIcon.classList.toggle('bi-caret-down-fill', !isVisible);
      row.classList.toggle('expanded', !isVisible);
    }
  } else if (event.target.classList.contains("active-signer")) {
    render(
      <div>
        <HandleSignatureBox event={event} dispatch={dispatch} currentUrlId={currentUrlId} rootDocData={rootDocData} />
      </div>,
      event.target
    );
  } else if (event.target.hasAttribute("data-co-buyer-broker-delete-icon") || event.target.hasAttribute("data-co-seller-broker-delete-icon") ) {
    showDeleteConfirmToBroker({event,  rootDocData, currentUrlId, handleInputSave})
  }
};

export const handleInputEvent = ({event, currentUrlId, handleInputSave, rootDocData}) => {
  if (event?.target?.classList?.contains('noAutoSave') || event?.target?.classList?.contains('relativeDateInput')) {
    return;
  }
 // console.log("input fired for", event.target.name);
  if (event.target.type === "checkbox") {
    let specificData = {};
    if (event.target.dataset?.cbGroup && event.target.checked) {
      // console.log("has cb-group that needs updated");
      handleCheckboxGroupUpdate(event.target.value, event.target.dataset.cbGroup, specificData);
    }

    if (event?.target?.name === "DisableAutoPriceCaluations") {
      handleToggleAutoPriceCalculation(event, currentUrlId, handleInputSave, specificData);
    }
    if (event?.target?.name === "AutoFillDates") {
      handleToggleAutoFillDates(event, currentUrlId, handleInputSave, specificData);
      return;
    }
    const elements = Array.from(document.querySelectorAll(`[name="${event.target.name}"]:checked`));
    const values = elements.filter((e) => e.value !== `${e.name}__Other` && e.value !== "").map((e) => e.value);
    // console.log("elements", elements);
    // console.log("values", values);
    if (values?.length) {
      specificData[event.target.name] = values;
    } else {
      specificData[event.target.name] = "";
    }
   
    if (event.target.value === "Deleted" && event.target.checked === false) {
      specificData[event.target.name] = "";
      // specificData[`${event.target.name}__valueChanged`] = true;
    }

    // If no change checkbox is un-checked manually to remove date/Time
    if (event.target.value === "No Change" && event.target.checked === false) {
      // console.log("coming inside this one",event.target)
      if (noChangeCheckboxContractTypes?.includes(rootDocData?.contractType)) {
        // specificData[`${event.target.name}__valueChanged`] = true;
        specificData[event.target.name] = "";
        needsDbUpdate = true;
      }
    }

    // logic for None included checkboxes - if other than None is checked - remove None if checked before
    if (event.target.checked && !event.target.dataset?.linkedwithNone) {
      const elements = document.querySelectorAll(`[name="${event.target.name}"]:checked`);
      const noneElement = Array.from(elements).find((e) => e.getAttribute("data-linkedWith-none") === `${event.target.name}_None` && e.checked);
      if (noneElement) {
        noneElement.checked = false;
        if (specificData[event.target.name].includes(noneElement.value)) {
          // Remove "None" from the array
          specificData[event.target.name] = specificData[event.target.name].filter((value) => value !== noneElement.value);
        }
      }
    }

    // logic for None included checkboxes - if None is checked- remove others if checked before
    if (event.target.hasAttribute(`data-linkedWith-none`) && event.target.dataset?.linkedwithNone && event.target.checked) {
      specificData[event.target.name] = [event.target.value];
      const elements = Array.from(document.querySelectorAll(`[name="${event.target.name}"]:checked`));
      const elementsToBeUnchecked = elements.filter((e) => !e.hasAttribute("data-linkedWith-none") && e.value !== "").map((e) => e);
      elementsToBeUnchecked.forEach((el) => {
        el.checked = false;
      });
    }

    if (event.target.hasAttribute("data-linked-value") && !event.target.checked) {
      event.target.value = `${event.target.name}__Other`;
      // console.log("data-linked-value", event.target.value);
      document.querySelectorAll(`[data-linked-value="${event.target.name}"][name="${event.target.dataset.linkedValue}"]`).forEach((element) => {
        element.value = "";
        specificData[element.name] = "";
        if (element.parentNode.classList.contains("grow-with-input") || element.parentNode.classList.contains("grow-with-content")) {
          element.parentNode.dataset.replicatedValue = "";
        }
      });
    }

    if (event.target.dataset?.enableSource && !event.target.checked) {
      document.querySelectorAll(`[data-enable-target="${event.target.dataset?.enableSource}"]`).forEach((element) => {
        if (element.type === "checkbox") {
          element.checked = false;
        } else {
          element.value = "";
          if (element.parentNode.classList.contains("grow-with-input") || element.parentNode.classList.contains("grow-with-content")) {
            element.parentNode.dataset.replicatedValue = "";
          }
        }
        specificData[element.name] = "";
      });
    }

    if (event.target.hasAttribute("data-enable-target") && event.target.checked) {
      // checkbox needs another checkbox to be checked
      // console.log(event.target.name, " has a target that needs to be checked.");
      document.querySelectorAll(`[data-enable-source="${event.target.dataset.enableTarget}"]`).forEach((element) => {
        element.checked = true;
        element.dispatchEvent(new Event("input"));
      });
    }

    if (event.target.dataset?.removeTarget) {
      // console.log("has a remove target");
      const isSpecificData = handleConfirmModal({event, specificData, removeSection, handleInputSave, currentUrlId});
      if (isSpecificData) {
        specificData = {
          ...isSpecificData
        }
        needsDbUpdate = true;
      } else {
        needsDbUpdate = false;
        return;
      }
    }

    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};

    if (rootDocData?.contractType === 'TO' && event.target.name === 'DoubleSidedTransaction') {
      // after unchecking the DoubleSidedTransaction remove the copied side of the values on TO form
      if (event.target.checked === false) {
        if (localData['SellerBrokerLicenseNumber']?.trim() === localData['BuyerBrokerLicenseNumber']?.trim() && localData['SelectedBy']) {
          if (localData['SelectedBy'] === 'SELLER_AGENT') {
            // make selling company blank
            const sellingCompanyInputFields = checkSellingContainer("", "getFieldNames");
            sellingCompanyInputFields?.forEach((ele)=> {
              let inputField = Array.from(document.getElementsByName(ele))[0];
              if (inputField) {
                inputField.value = "";
              }
            })
          } else if (localData['SelectedBy'] === 'BUYER_AGENT') {
            // make listing company blank
            const listingCompanyInputFields = checkListingContainer("", "getFieldNames");
            listingCompanyInputFields?.forEach((ele)=> {
              let inputField = Array.from(document.getElementsByName(ele))[0];
              if (inputField) {
                inputField.value = "";
              }
            })
          }
        }
      } else {
        if (localData['SellerBrokerLicenseNumber']?.trim() === localData['BuyerBrokerLicenseNumber']?.trim() && localData['SelectedBy']) {
          if (localData['SelectedBy'] === 'SELLER_AGENT') {
            specificData = pasteListingCoDataIntoSellingCo({localData,specificData});
          }
          else if (localData['SelectedBy'] === 'BUYER_AGENT') {
            specificData = pasteSellingCoDataIntoListingCo({localData, specificData});
          }
        }
      }
    }

    localData = {
      ...localData,
      ...specificData,
    };
    localStorage.setItem(currentUrlId, JSON.stringify(localData));

    if (event?.detail?.noApiCall) {
      return;
    }
    handleInputSave(specificData);
    specificData = {};
    globalSpecificData = {};
  } else {
    let specificData = {};
    needsDbUpdate = true;
    if (event.target.hasAttribute("data-calculatepricetable")) {
      return;
    }
    // not a checkbox
    if (event.target.hasAttribute("data-additional-find-key") && event.target.hasAttribute("data-additional-find-value")) {
      // console.log("input fired for field with data-additional-find-key and data-additional-find-value");
      specificData["_toFind"] = {
        name: event.target.dataset.additionalFindKey,
        value: event.target.dataset.additionalFindValue,
      };
    }

    if (event.target.hasAttribute("data-linked-value")) {
      // console.log("element has linked value");
      const element = document.querySelector(`[name="${event.target.dataset.linkedValue}"][data-linked-value="${event.target.name}"]`);
      element.value = event.target.value;
      element.checked = true;
      element.dispatchEvent(
        new CustomEvent("input", {
          detail: {
            noApiCall: true,
          },
        })
      );
      if (event.target.type === "text" && event.target.value) {
        let localData = JSON.parse(localStorage.getItem(currentUrlId));
        specificData[event.target.getAttribute("data-linked-value")] = localData[event.target.getAttribute("data-linked-value")];
      }
    }
    specificData[event.target.name] = event.target.value;
    if (event.target.dataset?.enableTarget) {
      // console.log("added value connected with checkbox, enabling checkbox and clearing any in cbgroup");
      document.querySelectorAll(`[data-enable-source="${event.target.dataset?.enableTarget}"]`).forEach((element) => {
        element.checked = true;
        // console.log("element", element);
        specificData[element.name] = element.value;
        if (element.dataset?.cbGroup) {
          handleCheckboxGroupUpdate(element.value, element.dataset.cbGroup, specificData);
        }
      });
    }

    if (event.target.parentNode.classList.contains("grow-with-input") || event.target.parentNode.classList.contains("grow-with-content")) {
      event.target.parentNode.dataset.replicatedValue = event.target.value;
    }
   
    if(rootDocData?.contractType === 'TO'){
      specificData.SelectedBy = rootDocData?.openFor
    }
    globalSpecificData = {
      ...globalSpecificData,
      ...specificData,
    };
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    if (event.target.classList.contains("currency") && event.target.value !== localData[event.target.name]) {
      priceInputFieldChange = true;
    }

    if ( rootDocData?.contractType === 'TO' && document.getElementsByName('DoubleSidedTransaction')[0].checked && (event.target.value || event.target.value === "")) {
      if (localData['SellerBrokerLicenseNumber']?.trim() === localData['BuyerBrokerLicenseNumber']?.trim() && localData['SelectedBy']) {
        if (localData['SelectedBy'] === 'SELLER_AGENT' && checkListingContainer(event.target.name, "checkIsPresent")) {
          specificData = updateIndividualChangedField({titleCompanySelectedBy: "SELLER_AGENT", localData, fieldUpdated: event.target.name, specificData, value: event.target.value});
        }
        else if (localData['SelectedBy'] === 'BUYER_AGENT' && checkSellingContainer(event.target.name, "checkIsPresent")) {
          specificData = updateIndividualChangedField({titleCompanySelectedBy: "BUYER_AGENT", localData, fieldUpdated: event.target.name, specificData, value: event.target.value});
        }
      }
      globalSpecificData = {
        ...globalSpecificData,
        ...specificData,
      }
    }

    if (event.target.classList.contains("formatPhoneNumber")) {
      event.target.value = formatPhoneNumber(event.target.value);
      specificData[event.target.name] = event.target.value;
    }

    localData = {
      ...localData,
      ...specificData,
    };
    localStorage.setItem(currentUrlId, JSON.stringify(localData));
    if (event?.detail?.noApiCall) {
      return;
    }

    clearTimeout(timeoutInput);
    timeoutInput = setTimeout(() => {
      if (needsDbUpdate) {
        handleBlurEvent({event, currentUrlId, handleInputSave});
        specificData = {};
        globalSpecificData = {};
        needsDbUpdate = false;
      }
    }, 5000);
  }
};

export const handleBlurEvent = ({event, currentUrlId, handleInputSave}) => {
  if (event?.target?.classList?.contains('noAutoSave') || event?.target?.classList?.contains('relativeDateInput')) {
    return;
  }
  // console.log("blur fired for", event.target.value);
  if (event.target.type === "checkbox") {
    return;
  }

  let checkBoxValues = {};
  let specificData = {};
  let localData = JSON.parse(localStorage.getItem(currentUrlId));
  if (event.target.hasAttribute("data-linked-value") && event.target.type === "text" && !event.target.value) {
    document.querySelectorAll(`[name="${event.target.dataset.linkedValue}"][data-linked-value="${event.target.name}"]`).forEach((element) => {
      element.checked = false;
      element.value = event.target.name;
      element.dispatchEvent(new Event("input"));
      needsDbUpdate = true; // it may be need outside of forEach
    });
    // needsDbUpdate = true;
  }

  if (event.target.hasAttribute("data-linked-value") && event.target.type === "text" && event.target.value) {
    let localData = JSON.parse(localStorage.getItem(currentUrlId));
    checkBoxValues[event.target.getAttribute("data-linked-value")] = localData[event.target.getAttribute("data-linked-value")];
    needsDbUpdate = true;
  }

  if (event.target.dataset?.enableTarget && !event.target.value) {
    document.querySelectorAll(`[data-enable-source="${event.target.dataset.enableTarget}"]`).forEach((element) => {
      element.checked = false;
      specificData[element.name] = null;
    });
    needsDbUpdate = true;
  }

  if (event.target.classList.contains("currency") && priceInputFieldChange) {
    if (event.target.value !== "") {
      let value = accounting.formatNumber(event.target.value, 2);
      specificData[event.target.name] = value;
      event.target.value = value;
      if (event.target.parentNode.classList.contains("grow-with-input") || event.target.parentNode.classList.contains("grow-with-content")) {
        event.target.parentNode.dataset.replicatedValue = value;
      }
    } else {
      specificData[event.target.name] = null;
    }
    needsDbUpdate = true;
    priceInputFieldChange = false;
  }

  if (event.target.hasAttribute("data-calculatepricetable") && event.target.value !== localData[event.target.name]) {
    const fieldsToGet = ["PurchasePrice", "EarnestMoney", "NewLoan", "AssumptionBalance", "PrivateFinancing", "SellerFinancing"];

    const tableValues = {};

    fieldsToGet.forEach((field) => {
      const elements = document.getElementsByName(field);
      if (elements.length) {
        let value = accounting.unformat(elements[0].value);
        // let value = elements[0].value;
        tableValues[field] = value;
      } else {
        tableValues[field] = 0;
      }
    });

    const updatedValues = calculatePriceTable(tableValues);
    // console.log("updatedValues", updatedValues);

    for (let key in updatedValues) {
      specificData[key] = updatedValues[key];

      document.getElementsByName(key).forEach((element) => {
        element.value = updatedValues[key];

        element.parentNode.dataset.replicatedValue = updatedValues[key];
      });
    }
    needsDbUpdate = true;
  }
  globalSpecificData = {
    ...globalSpecificData,
    ...specificData,
    ...checkBoxValues,
  };

  localData = {
    ...localData,
    ...globalSpecificData,
  };
  localStorage.setItem(currentUrlId, JSON.stringify(localData));

  if (needsDbUpdate) {
    handleClearTimeouts();
    // specificData[event?.target?.name] = event?.target?.value;
    handleInputSave({ ...globalSpecificData });
    specificData = {};
    globalSpecificData = {};
    needsDbUpdate = false;
  }
};
